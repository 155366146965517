<script>
import TablePage from '../../../../../components/table_page';
import configs from '../data';
import Form from '../form';
import Modal from '../../../../../components/modal';
import request from '../../../../../utils/request';

export default {
  extends: TablePage,
  components: {
    Modal,
    Form,
  },
  data() {
    return {
      requestUrl: '/mdm/mdmcoderule/list',
      configs,
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  created() {},
  methods: {
    modalClick({ val, row }) {
      this.formConfig = {};
      if (val.code === 'edit') {
        this.formConfig = {
          ...val,
          ...row,
        };
        this.modalConfig.title = '编辑';
        this.openModal();
      } else if (val.code === 'reset') {
        request.post('/mdm/mdmcoderule/reset', { ids: [row.id] }).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功',
            });
            this.getList();
          }
        });
      } else {
        this.openModal();
      }
    },
    deleteClick({ row }) {
      if (this.selection.length === 0) {
        this.$message('请选择一条数据');
        return;
      }

      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          request.post('/mdm/mdmcoderule/delete', this.selection).then((res) => {
            if (res.success) {
              this.$message({
                type: 'success',
                message: '删除成功',
              });
              this.getList();
            }
          });
        })
        .catch(() => {
        });
    },
  },
};
</script>
