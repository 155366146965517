<script>
import Form, { formCreate } from '../../../../../components/form';
import request from '../../../../../utils/request';
import TableForm from './components';

formCreate.component('TableForm', TableForm);

export default {
  extends: Form,
  components: {
    TableForm,
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [
        {
          type: 'select',
          field: 'ruleModule',
          title: '所属模块',
          dictCode: 'module_group',
          validate: [
            {
              trigger: 'change',
              required: true,
              message: '请选择所属模块',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'ruleCode',
          title: '业务key',
          refresh: true,
          props: {
            placeholder: '请输入业务key',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'blur',
              required: true,
              message: '请输入业务key',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'ruleDescription',
          title: '规则描述',
          refresh: true,
          props: {
            placeholder: '请输入规则描述',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'blur',
              required: true,
              message: '请输入规则描述',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'prefix',
          title: '前缀',
          refresh: true,
          props: {
            placeholder: '请输入前缀',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
          },
          // validate: [
          //   {
          //     trigger: 'blur',
          //     required: true,
          //     message: '请输入前缀',
          //   },
          // ],
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'dateFormat',
          title: '时间戳',
          value: this.formConfig && this.formConfig.dateFormat,
          refresh: true,
          props: {
            placeholder: '请输入时间',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'blur',
              required: false,
              message: '请输入时间',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'codeLength',
          title: '长度',
          refresh: true,
          props: {
            placeholder: '请输入长度',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'blur',
              required: true,
              message: '请输入长度',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },

        {
          type: 'input',
          field: 'initialValue',
          title: '起始值',
          refresh: true,
          props: {
            placeholder: '请输入起始值',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'blur',
              required: true,
              message: '请输入起始值',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'input',
          field: 'currentValue',
          title: '当前值',
          value: this.formConfig && this.formConfig.functionName,
          refresh: true,
          props: {
            placeholder: '请输入当前值',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'blur',
              required: false,
              message: '请输入当前值',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
      ],
    };
  },
  mounted() {
    const len = Object.keys(this.formConfig).length;

    if (len === 0 || (len > 0 && this.formConfig.functionLevel === '0')) {
      this.hiddenFields(true, ['parentCode']);
    }
    if (this.formConfig.id) {
      const data = {
        id: this.formConfig.id,
      };
      request.post('/mdm/mdmcoderule/query', data)
        .then((res) => {
          console.log(res);
          this.setValue(res.result);
        });
    }
  },
  methods: {
    changeFn(val) {
      this.hiddenFields(val === '0', ['parentCode']);
    },
    // 提交
    submit() {
      const formData = this.getFormData();

      if (formData) {
        let url = '/mdm/mdmcoderule/save';
        let params = formData;

        if (this.formConfig && this.formConfig.code === 'edit') {
          url = '/mdm/mdmcoderule/update';
          params = Object.assign(this.formConfig, formData);
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
