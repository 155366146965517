<template>
  <div class="home">
    <TablePage/>
    <!-- <TableForm /> -->
  </div>
</template>

<script>
import TablePage from './table';

export default {
  name: 'Home',
  components: {
    TablePage,
  },
  created() {
    // this.getListData();
  },
  methods: {},

  data() {
    return {};
  },
};
</script>
