export default {
  formConfig: {
    titleWidth: 100,
    titleAlign: 'right',
    item: [
      {
        field: 'ruleModule',
        search: true,
        title: '所属模块',
        type: 'select',
        apiUrl: '/mdm/mdmdictdata/list',
        optionsKey: {
          label: 'dictValue',
          value: 'dictCode',
        },
        paramsFixed: {
          dictTypeCode: 'module_group',
        },
      },
      {
        field: 'ruleCode',
        type: 'input',
        title: '业务Key',
        search: true,
      },
      {
        field: 'prefix',
        type: 'input',
        title: '前缀',
        search: true,
      },
      {
        field: 'dateFormat',
        type: 'input',
        title: '时间',
        search: true,
      },
      {
        field: 'ruleDescription',
        type: 'input',
        title: '规则描述',
        search: true,
      },
    ],
  },
  toolBarConfig: [
    {
      type: 'button',
      // buttonType: 'primary',
      // icon: 'el-icon-plus',
      code: 'add',
      name: '新增',
    },
    {
      type: 'button',
      buttonType: null,
      // icon: 'el-icon-delete',
      name: '删除',
      code: 'delete',
    },
  ],
  columns: [
    {
      fixed: 'left', // 固定位置在左边
      type: 'checkbox', // 单选
      title: null,
      width: '50',
    },
    {
      fixed: 'left',
      type: 'seq', // 显示序号
      title: '序号',
    },
    {
      field: 'ruleModuleName', // 显示序号
      title: '所属模块',
    },
    {
      field: 'ruleCode',
      title: '业务Key',
    },
    {
      field: 'prefix',
      title: '前缀',
    },
    {
      field: 'dateFormat',
      title: '时间戳',
    },
    {
      field: 'codeLength',
      title: '长度',
    },
    {
      field: 'ruleDescription',
      title: '规则描述',
    },
    {
      field: 'initialValue',
      title: '起始值',
    },
    {
      field: 'currentValue',
      title: '当前值',
    },
    {
      field: 'generateDate',
      title: '生成时间',
    },
    {
      field: 'createName',
      title: '创建人',
    },
    {
      title: '操作',
      width: '120',
      align: 'center',
      buttons: [
        {
          type: 'button',
          buttonType: 'primary',
          code: 'edit',
          name: '编辑',
          props: {
            type: 'primary',
          },
        },
        {
          type: 'dropdown',
          buttonType: 'link',
          code: 'delete',
          name: '删除',
          props: {
            type: 'primary',
          },
        },
        {
          type: 'button',
          buttonType: 'primary',
          code: 'reset',
          name: '重置当前',
          props: {
            type: 'primary',
          },
        },
      ],
    },
  ],
  tableConfig: {
    border: true,
    // treeConfig: {
    //   children: 'children',
    // },
    idKey: 'id', // 默认勾选判断字段
    resizable: true, // 所有的列是否允许拖动列宽调整大小
    showOverflow: true, // 当内容过长时显示为省略号
    keepSource: false, // 保持原始值的状态，被某些功能所依赖，比如编辑状态、还原数据等（开启后影响性能，具体取决于数据量）
    highlightHoverRow: true, // 鼠标滑动高亮
    // height: '600',
  },
};
